@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
/* App color */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
:root {
  --dark-mode-bg-main-body-color: #181818;
  --dark-mode-bg-card-color: #201F1F;
  --dark-mode-text-color: #E6E6E6;
  --dark-mode-border-color: #323232 ;
  --dark-mode-text-color-light-gray: #bcbbbb;
  
  --light-mode-bg-main-body-color: #FDFDFD;
  --light-mode-bg-card-color: #FFF;
  --light-mode-text-color: #002E74;
  
  --dark-gray-color: #3F3F3F;
  --light-gray-color: #E6E6E6;
  --gray-color: #5C5C5C;
  --pale-blue-color: #D9DFF8;
  --light-shade-black-color: #1E1E1E;
  --black-color: #121212;
}
.bg-app {
  background-color: rgb(32,33,35);
}
.bg-light-app {
  background-color: #d5f9d6;
}
.bg-button {
  background-color: rgb(32,33,35);
}
div.dk-bg-button,
button.dk-bg-button {
  background-color: rgb(32,33,35);
}

.text-app {
  color: rgb(32,33,35);
}

.border-app {
    border: solid;
    border-width: 2px;
    border-color: rgb(32,33,35);
}

label > input[type="radio"]:checked + *::before {
  background: radial-gradient(
    rgb(32,33,35) 0%,
    rgb(32,33,35) 40%,
    transparent 50%,
    transparent
  );
  border-color: #3f0e40;
}

.text-app-color {
  color: rgb(32,33,35);
}

/* App color */

.hide-scroll-bar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.custom-scroll::-webkit-scrollbar {
    width: 5px;
    overflow-y: scroll !important;
    scrollbar-width: thin !important;
  }

  /* Track */
  .custom-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f4f4f6;
    border-radius: 10px;
  }

  /* Handle */
  .custom-scroll::-webkit-scrollbar-thumb {
    background: #d6d5d5;
    border-radius: 10px;
  }

  /* Handle on hover */
  .custom-scroll::-webkit-scrollbar-thumb:hover {
    background: gray;
  }

  div.warning-tooltip-prop {
    background-color: #fef6e1;
    max-width: 500px;
  }

.dk-kanban {
  user-select: none;
  /* overflow: scroll !important; */
  scroll-behavior: smooth;
}
.dk-kanban > .dk-kanban-stage-wrapper {
  user-select: none !important;
  /* background: #e6e6ee; */
}
.dk-kanban
  > .dk-kanban-stage-wrapper
  > .dk-kanban-card-wrapper
  > .dk-kanban-card {
  transition: transform 0.15s ease;
}
.dk-kanban
  > .dk-kanban-stage-wrapper
  > .dk-kanban-card-wrapper
  > .dk-kanban-card
  > .dk-kanban-default-card {
  background-color: #ffffff;
}
.cursor-grabbing {
  cursor: grabbing;
}
.cursor-move {
  cursor: move;
}
.d-flex {
  display: flex;
}
.stage-toggle-button:hover {
  background-color: #5a64d7;
}
/* Style the tab */
.tab {
  overflow: hidden;
}

.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

.tab .tablinks:hover {
  color: #5a64d7;
}

.tab .tablinks.active {
  color: #5a64d7;
  border-bottom: 2px solid #5a64d7;
}

.tabcontent {
  display: none;
  padding: 6px 12px;
  border-top: none;
}
/* .no-email-wrap {
  background-image: url("../assets/no-mail.svg");
  min-height: 13rem;
  background-position: right bottom;
  background-size: 12rem;
  background-repeat: no-repeat;
} */
.note-textarea {
  border-color: transparent !important;
  resize: none;
  background-color: #fef6e1;
}
.note-textarea:focus {
  border-color: transparent !important;
  resize: none;
  outline: none;
}
.stage-toggle-wrap div:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.stage-toggle-wrap div:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.selected-stage {
  background-color: #5a64d7 !important;
}
.deal-card-icon {
  -webkit-user-drag: none;
}
.data-link-wrapper > a {
  height: 2rem;
  line-height: 1rem;
  overflow: hidden;
}
.data-link-wrapper > a:visited {
  color: blue;
}
.dashboard-card-wrapper > .dashboard-card {
  width: 32%;
  margin-top: 1%;
  margin-bottom: 1%;
  /* margin-right: 1%; */
}
.dashboard-card-wrapper > .dashboard-card:nth-child(3n) {
  margin-right: 0%;
}
@media (max-width: 1025px) {
  .dashboard-card-wrapper > .dashboard-card {
    width: 49%;
  }
}
@media (max-width: 650px) {
  .dashboard-card-wrapper > .dashboard-card {
    width: 98%;
    margin-bottom: 2%;
  }
}

/* Borders */
.border-m-b {
  border: 0 solid rgb(220, 220, 220);
  border-bottom-width: 1px;
}

/* Radio Button Styles */
label > input[type="radio"] {
  display: none;
}

label > input[type="radio"] + *::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 0.3rem;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.1rem;
  border-color: gray;
}


.flex-shrink-0 {
  flex-shrink: 0;
}
.stage-holder:hover .stage-wrap .stage-delete-icon {
  display: block;
}
.stage-holder > .stage-wrap > .stage-delete-icon {
  display: none;
}

.sidebar-transition-overflow {
  transition: 0.5s;
  overflow: hidden;
}

.sidebar-collapsed-width {
  min-width: 60px;
}

.sidebar-expanded-width {
  min-width: 180px;
}

.template-active {
  border: 2px solid blue;
}

.column-type-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.canvas-active {
  border: 0.5px solid rgb(0, 89, 255);
}

/* Horizontal Scroll Bar styles start */

.h-scroller {
  scrollbar-width: thin;
}

.h-scroller::-webkit-scrollbar {
  height: 0.5em;
  background-color: #f4f4f6;
}

.h-scroller::-webkit-scrollbar-thumb {
  background-color: #ccc;
}

/* Horizontal Scroll Bar styles End */

[contenteditable]:focus {
  outline: 0px solid transparent;
}

[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}

.text-underline {
  text-decoration: underline;
}

.max-width-100 {
  max-width: 100%;
}
/* Chrome, Safari, Edge, Opera */
input.incrementor-input::-webkit-outer-spin-button,
input.incrementor-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.incrementor-input[type="number"] {
  -moz-appearance: textfield;
}
.funnel-stepper-rollover {
  position: absolute;
  width: fit-content!important;
  z-index: 4;
  top: -30px;
}
.segment-multi-select>div.mr-l{
  margin : 0px !important
}
.segment-ctrl-first {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
}
.segment-ctrl-last {
  border-radius: 0 !important;
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

/* .cust-card {
    background-image: url('../assets/images/Mask_Group_1.svg'), url('../assets/images/Group_31617.svg');
    background-position: left 60% bottom -1px, right center;
    background-repeat: no-repeat;
    background-size: auto 101%, auto 100%;
    background-origin: border-box, content-box;
} */

.sidebar {
  transition: width 0.3s;
}

.expandable-button {
  visibility: hidden;
}
.sidebar:hover .expandable-button {
  visibility: visible;
}

.sidebar-toggle {
  transition: left 0.3s;
}
.sidebar-menu-item {
  transition: visibility 0.3s;
}
.input-underline {
  border-bottom: 1px solid #dcdcdc;
}
.text-underline {
  text-decoration: underline;
}
.left-menu-button {
  text-decoration: none;
}

.active-menu-button,
.left-menu-button:hover {
  background-color: rgba(255, 255, 255, 0.08);
}
.hover-effect:hover {
  background-color: rgb(236, 236, 236);

}
.document-view {
  height: calc(88vh - 129px);
  overflow-y: auto;
}
.doc-showdow {
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.pl-0 {
  padding-left: 0px !important;
}
/* .react-pdf__Page{
  box-shadow: 0 0 8px rgb(0 3 0 / 50%);
  margin: 10px auto 0;
} */

@-moz-document url-prefix() {
  .pl-o{
    padding-left: 0px !important;
  }
  .ml-o{
    margin-left: 0px !important;
  }
  .input-w{
    width: -moz-available;
  }
  .ot-align {
    margin-bottom: -12px !important;
  }

}
.card-shadow {
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.37) !important;
}
.text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
}
.text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107,114,128,var(--tw-text-opacity));
}
.mb-0\.5 {
    margin-bottom: .125rem;
}
.mt-1 {
    margin-top: .25rem;
}
.px-3\.5 {
    padding-left: .875rem;
    padding-right: .875rem;
}
.focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow,0 0 transparent);
}
*, ::after, ::before {
    --tw-ring-inset: var(--tw-empty, );
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(59,130,246,0.5);
    --tw-ring-offset-shadow: 0 0 transparent;
    --tw-ring-shadow: 0 0 transparent;
}
.focus\:ring-red-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239,68,68,var(--tw-ring-opacity));
}
.rounded {
    border-radius: .25rem;
}
.focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.font-normal {
    font-weight: 400;
}
.border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(209,213,219,var(--tw-border-opacity));
}
.border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248,113,113,var(--tw-border-opacity));
}
.w-92 {
    width:'92%'
}
.border {
    border-width: 1px;
}
.rounded {
    border-radius: .25rem;
}

/* .paid-card {
    width: 15vw;
    background-image: url('../assets/images/Paid.svg');
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: auto 50%;
} */
.input-width {
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
}
.description-cashfree {
  text-align: center;
  word-wrap: break-word;
  word-break: break-all;
  text-align-last: left;
  font-size: 1.05em
}

.overtime01 {
    height: 30px;
    border-width: thin;
    border-radius: 5px 5px 5px 5px;
    border-color: lightgrey;
    border-style: solid;
}

.overtime02 {
    height: 30px;
    border-width: thin;
    border-radius: 5px 5px 5px 5px;
    border-color: lightgrey;
    border-style: solid;
    margin-left:5px
}

.sidebar-arrow-icon{
  transition: transform 0.3s ease-out;
}
.rotate-180 {
  transform: rotate(180deg);
}
.rotate-360 {
  transform: rotate(360deg);
}

.bottom-margin {
  margin-bottom: -12px;
}
.border-n {
  border: none !important;
}

.info_error_icon {
    height: 18px;
    width: 18px;
    min-width: 18px;
    min-height: 18px;
}

.tooltip {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
}

.tooltip .tooltiptext {
  visibility: visible;
  /* color: white; */
  border-radius: 4px;
  margin: 5px;
  max-width: 250px;
  width: 120px;
  padding: 8px;
  /* padding-right: 16px; */
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: grey;
  /* top: 0;
  left: 0;
  width: 220px;
  max-width: 120px;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px; */
  /* margin: 5px; */

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -10px;
  left: 105%;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.toogle-view :first-of-type {
  margin-right: 2px;
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.w-70 {
  width: 70%;
}

.w-30 {
  width: 30%
}

.w-50 {
  width: 50%
}

.incomplete-profile-row {
    background-color: #FEECEA;
}



.navigation-scrollbar {
    overflow: hidden;
}

.navigation-scrollbar:hover {
    overflow-y: auto;
    overflow-x: hidden;
}

.scrollbar-thin {
    scrollbar-width: thin;
}

.pointer-events-none {
    pointer-events: none;
}
.pb-xs{
  padding-bottom: 5px;
}

.variable-value {
  margin-top: -8px;
}
.disable-section {
    pointer-events: none;
    opacity: 0.75;
}

.disable-button {
    pointer-events: none;
    opacity: 0.75 !important;
}
.earning{
  background-color: #e8f5e9;
}
.deduction {
  background-color: #ffebee;
}
.expander-shadow {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}
.text-black {
  color: #000;
}
.d-none{
  display: none;
}
.w-45 {
  width: 45px;
}
.m-0{
  margin: 0px;
}
.h-70 {
  height: 70px;
}
.mt-0{
  margin-top: 0px;
}
.mt-auto {
    margin-top: auto !important;
}
.data-grid-badge-color-11{
  background-color: #ecbe90c0 !important;
}

.data-grid-badge-color-12{
  background-color: #b6d1e4 !important;
}

.ic-xxl{
  height: 120px;
}

.visibility-hidden {
    visibility: hidden;
}

.visibility-visible {
    visibility: visible;
}

.align-items-center-compo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.pb-4{
  padding-bottom: 4px;
}
.w-80 {
  width: 80%;
}
.w-100 {
  width: 100%;
}
/* RESPONSIVE */
.notification-popup {
    width: 330px;
    position: absolute;
    margin: 5px auto;
}

.left-vert-divider {
    border-left: 2px #dededf solid;
    padding-left: 12px;
}

.text-msg-holder {
    display: flex;
    flex-flow: row wrap;
}

.vertical-spacing {
    margin-top: 0px;
}

.white-space-no-wrap {
    white-space: nowrap !important;
}

.mobile-width-auto {
    width: 400px;
}

.web-width-70 {
    width: 70%
}

.web-max-width-70 {
  max-width: 69%
}

.web-width-30 {
    width: 30%
}

.web-width-35 {
    width: 35%
}

.web-width-40 {
    width: 40%
}
.web-width-50 {
  width: 50%
}

.web-width-15 {
    width: 15%
}
.web-mr-l {
  margin-right:18px
}
.web-ml-auto {
  margin-left: auto;
}
.web-text-right{
  text-align: right !important;
}
.web-d-flex {
  display: flex;
}
.attachment-file-w {
  width: 54%;
}
@media only screen and (max-width: 800px) {
    .dk-sidebar-menu-toggle.expandable-menu-button {
        visibility: visible;
    }
    .notification-popup {
        width: 90vw;
        position: fixed;
        left:0;
        margin: 15px auto;
    }
    div.banner-holder {
        display: none;
    }
    .cust-card {
        background-image: url("//")
    }
    .left-vert-divider {
        border-left: none;
        padding-left: 0px;
    }
    .text-msg-holder {
        display: flex;
        flex-flow: column nowrap;
        text-align: left;
    }
    .vertical-spacing {
        margin-top: 8px;
    }
    .justify-content-center-mobile {
        justify-content: center !important;
    }
    .justify-content-start-mobile {
        justify-content: flex-start !important;
    }
    .editor-background-mobile {
        width: 100vw;
        height: 100%;
        position: absolute;
        z-index: 9;
        background: rgba(0, 0, 0, 0.3);
    }
    .padding-empty-mobile {
        padding:0 !important;
    }
    .padding-top-mobile {
        padding-top: 12px !important;
    }
    .padding-bottom-mobile {
        padding-bottom: 6px !important;
    }
    .padding-bottom-mobile-10 {
      padding-bottom: 10px !important;
    }
    .marging-bottom-mobile {
      margin-bottom: 8px;
    }
    .margin-empty-mobile {
        margin:0 !important;
    }
    .mobile-width-auto {
        width: auto;
        margin-top: 8px;
    }
    .web-width-70 {
        width: 100%
    }

    .web-max-width-70 {
      max-width: 100%
    }

    .web-width-30 {
        width: 100%
    }
    .web-width-40 {
        width: 100%
    }
    .web-width-50 {
      width: 100%
    }
    .web-width-15 {
        width: 100%
    }
    .margin-top-mobile {
        padding-top: 30px !important;
    }
    .mobile-parent-width {
        width: 100% !important;
    }
    .mobile-horizontal-width {
        margin-left: 18px;
        margin-right: 18px;
    }
    .web-mr-l {
      margin-right: 0px;
    }
    .web-width-35 {
        width: 100%
    }
    .web-ml-auto {
      margin-left: inherit;
    }
    .web-text-right{
      text-align: inherit !important;
    }
    .web-d-flex {
      display: initial;
    }
    .attachment-file-w {
      width: 100%;
    }
}
.to-lower-case {
  text-transform: lowercase;
}
.ml-auto{
  margin-left: auto;
}
.cursor-pointer {
  cursor: pointer;
}

.width-tooltip-copy{
  justify-content: center;
  display: flex;
}
.controll-input::-webkit-outer-spin-button,
.controll-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.controll-input[type=number] {
  -moz-appearance: textfield;
}

.collapsable>div>div.fs-r{
  font-size:16px !important
}

.popup-overflow{
  overflow: visible !important;
}

@media only screen and (max-width: 800px) {
    .paid-card {
        background-image: none;
    }
}
.img-preview {
  width: 53%;
}
.d-flex-end {
  justify-content: flex-end !important;
}

.work-week-btn {
  border-radius: 4px !important;
}
.selected {
  background-color: #E6F2FF;
}
.border_bottom_workweek {
  border-bottom: 1px solid #ddd;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.dashboard-no-data {
  min-height: 230px;
}
.white-space-break >
  text{
    white-space: break-spaces !important;
    line-break: anywhere;
  }
.line-break-anywhere {
  line-break: anywhere;
}
.warning-tooltip-prop {
  background-color: #fef6e1;
  max-width: 500px;
}
.pt-0{
  padding-top: 0px !important;
}
.pb-0{
  padding-bottom: 0px !important;
}
.ml-6 {
  margin-left: 6px !important;
}

.email-view {
    position: fixed;
    transform: translate(-50%,-50%);
    left: 50%;
    z-index: 9998;
    background-color: #fff;
    border-radius: 8px;
    padding: 15px 20px;
    top: 50%;
    font-size: 13px;
    width: 35%;
    height: 65%;
    text-align: left;
    box-shadow: 0 0 12px rgb(0 0 20 / 14%);
}
.payment-method-popup {
  min-height: 270px;
  padding: 16px !important;
}

/* ChatBot */
.main-body {
  // width: 90%;
  background-color: var(--light-mode-bg-main-body-color);
  transition: 0.2s;
  &-dark {
    transition: 0.2s;
    background-color: var(--dark-mode-bg-main-body-color);
  }
}
.main-body * {
  transition: 0.2s;
}
.main-body-dark * {
  transition: 0.2s;
}
.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0.8;
  position: relative;
  &-dark {
    @extend .main-container;
  }
  @media (max-width: 1300px) {
    // width: 100%;
    flex: 1;
  }
}
.left-menu {
  flex: 0.2;
  &-dark {
    @extend .left-menu;
  }
}
@media (max-width: 1300px) {
  .left-menu {
    flex: 0;
  }
}
.header {
}
.chat-heading {
  margin-bottom: 20px;
  color: var(--light-mode-text-color);
  font-weight: 500;
  font-size: 0.96694rem;
  line-height: 100%;
  border: 1px solid var(--light-gray-color);
  &-dark {
    @extend .chat-heading;
    color: var(--dark-mode-text-color);
    border: 1px solid var(--dark-mode-border-color);
  }
}
.toggle-switch-container {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}
.switch {
  position: relative;
  display: inline-block;
  justify-self: flex-end;
  width: 3rem;
  height: 1.5rem;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  input:checked + .slider {
    background-color: var(--dark-mode-bg-card-color);
  }
  input:checked + .slider:before {
    background-color: var(--light-gray-color);
    transform: translateX(1.55rem);
  }
  .slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--light-gray-color);
    border-radius: 10rem;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 1rem;
    width: 1rem;
    left: 0.25rem;
    bottom: 0.26rem;
    border-radius: 100%;
    background-color: var(--light-mode-bg-card-color);
    transition: 0.2s;
  }
}

.chat-container {
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 10%;
  // margin-bottom: 15%;
  // background-color: red;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height:28px;
  &-dark {
    @extend .chat-container;
    &::-webkit-scrollbar-thumb {
      background-color: var(--dark-mode-text-color-light-gray);
    }
    &::-webkit-scrollbar {
      background-color: var(--black-color);
    }
  }
  @media (max-width: 1300px) {
    width: 100vw;
  }
  @media (max-width: 800px) {
    padding-bottom: 22%;
  }
  
}
.user-message-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  align-items: center;
  padding: 0 1rem;
  font-size: 0.875rem;
  color: var(--light-mode-text-color);
  &-dark {
    @extend .user-message-container;
    color: var(--dark-mode-text-color);
  }
}

.user-message-intro {
  margin: 1rem;
  margin-left: 0;
  font-weight: 450;
  line-height: 28px;
  font-size: 15px;
  border: 1px solid var(--light-gray-color);
  &-dark {
    @extend .user-message-intro;
    border: 1px solid var(--dark-mode-border-color);
  }
}

.user-avatar {
  align-self: flex-start;
}

.bot-message-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  align-items: center;
  padding: 0 1rem;
  // margin-top: -50px;
  font-weight: 500;
  font-size: 0.875rem;
  &-dark {
    @extend .bot-message-container;
  }
}

.bot-avatar {
  align-self: flex-start;
}

.bot-message-intro {
  margin: 1rem;
  margin-left: 0;
  color: var(--light-mode-text-color);
  background-color: var(--light-mode-bg-card-color);
  box-shadow: 14px 27px 60px 4px rgba(142, 147, 255, 0.08);
  font-weight: 400;
  line-height: 26px;
  font-size: 15px;
  .markdown-msg {
    border-collapse: collapse;
    width: 100%;
    /* padding: 10px; */
    border-radius: 20px;
    clip: auto;
    th, td {
      border: 0.8px solid #ccc;
      padding: 8px;
      text-align: left;
    }
    table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0;
      border: 0.8px solid #bababa;
      margin-bottom: 20px;
      border-radius: 10px;
      overflow: hidden;
      tbody {
      }
      th, td {
        border: 0.8px solid #dcdcdc;
        padding: 8px;
      }
      th, td:nth-child(even) {
        text-align: center;
      }
      th {
        background-color: #5f59ff;
        color: white;
        font-weight: 500;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 1.2px;
      }
      tr:nth-child(even) {
        background-color: #f9f9f9;
      }
    }
  }
  &-dark {
    @extend .bot-message-intro;
    color: var(--dark-mode-text-color);
    background-color: var(--dark-mode-bg-card-color);
    box-shadow: none;
    .markdown-msg {
      @extend .markdown-msg;
      table {
        border: 1px solid #272727;
        tr:nth-child(even) {
          background-color: #181717;
        }
        th, td{
          border: 1px solid #272727;
        }
      }
    }
  }
}

.bot-message-graph-insight-container {
  display: flex;
  &-dark {
    @extend .bot-message-graph-insight-container;
  }
  @media (max-width: 1300px) {
    flex-direction: column;
  }
}

.bot-message-graph {
  margin: 1rem;
  margin-left: 0;
  overflow-y: hidden;
  flex: 2.6;
  color: var(--light-mode-text-color);
  background-color: var(--light-mode-bg-card-color);
  box-shadow: 14px 27px 60px 4px rgba(142, 147, 255, 0.08);
  &-dark {
    @extend .bot-message-graph;
    color: var(--dark-mode-text-color);
    background-color: var(--dark-mode-bg-card-color);
    box-shadow: none;
  }
  @media (max-width: 1300px) {
    flex: 1;
  }
}

.bot-message-insight {
  @extend .bot-message-intro;
  margin: 1rem;
  margin-left: 0;
  flex: 1.4;
  color: var(--light-mode-text-color);
  background: var(--pale-blue-color);
  &-dark {
    @extend .bot-message-insight;
  }@media (max-width: 1300px) {
    flex: 1;
  }
}

.bot-message-outro {
  @extend .bot-message-intro;
  &-dark {
    @extend .bot-message-outro;
    color: var(--dark-mode-text-color);
    background-color: var(--dark-mode-bg-card-color);
    box-shadow: none;
  }
}

.suggestions-container {
  margin-left: 5.5rem;
  &-dark {
    @extend .suggestions-container;
    color: var(--gray-color);
  }
}

.suggestions-list {
  display: flex;
  flex-wrap: wrap;
}

.suggestion-item {
  border: 1px solid var(--light-gray-color);
  font-weight: 500;
  line-height: 1.625rem;
  padding: 0.625rem;
  border-radius: var(--8, 0.5rem);
  font-size: 0.875rem;
  color: var(--light-mode-text-color);
  &:hover {
    background-color: var(--light-gray-color);
  }
  &-dark {
    @extend .suggestion-item;
    color: var(--dark-mode-text-color-light-gray);
    border-color: var(--dark-mode-border-color);
    &:hover {
      background-color: var(--dark-gray-color);
    }
  }
}
.home-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 30rem;
  &-dark {
    @extend .home-container;
  }

}
.home-heading-container {
  overflow: hidden;
  // width: 35rem;
  height: 2.48rem;
  &-dark {
    @extend .home-heading-container;
  }
}
.home-heading {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--dark-mode-text-color);
  cursor: default;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-dark {
    @extend .home-heading;
    color: var(--dark-mode-border-color);
  }
}
.home-heading-item {
  // animation: scrollAndJump 50s linear infinite; // Double the time for a 2-second interval
  // transform-origin: bottom;
  //  @keyframes scrollAndJump {
  //   0%, 5% {
  //     opacity: 1;
  //     transform: translateY(0px);
  //   }
  //   10%, 20% {
  //     transform: translateY(-100px);
  //     opacity: 1;
  //   }
  //   25%, 35% {
  //     transform: translateY(-200px);
  //     opacity: 1;
  //   }
  //   40%, 50% {
  //     transform: translateY(-300px);
  //     opacity: 1;
  //   }
  //   55%, 65% {
  //     transform: translateY(-390px);
  //     opacity: 1;
  //   }
  //   70%, 80% {
  //     transform: translateY(-490px);
  //     opacity: 1;
  //   }
  //   85%, 95% {
  //     transform: translateY(-550px);
  //     opacity: 1;
  //   }
  //   98%, 100% {
  //     transform: translateY(-590px);
  //     opacity: 0;
  //   }
  // }
  &-dark {
    @extend .home-heading-item;
  }
}
.home-suggestion-row-container {
  width: 100%;
  padding: 0;
}
.home-suggestion-row {
  display: flex;
  justify-content: center;
  width: 100%; /* Ensure the row takes the full width of the container */
  @media (max-width: 1300px) {
    flex-direction: column;
    // width: 95%;
  }
}
.home-suggestion-item {
  width: 50%; 
  border: 1px solid var(--light-gray-color);
  border-radius: 0.95238rem;
  font-weight: 500;
  line-height: 1.625rem;
  padding: 1.1905rem;
  font-size:  0.875rem;
  margin: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  
  .random-suggestion-title {
    letter-spacing: 0.0105rem;
    // color: var(--dark-mode-text-color-light-gray);
    &-dark {
      @extend .random-suggestion-title;
      color: var(--dark-mode-text-color-light-gray);
    }
  }
  .random-suggestion-question {
    font-weight: 400;
    letter-spacing: 0.0105rem;
    color: var(--gray-color);
    &-dark {
      @extend .random-suggestion-question;
      // color: #767676;
      // color: var(--gray-color);
    }
  }
  &:hover {
    background-color: var(--light-gray-color);
  }
  &-dark {
    @extend .home-suggestion-item;
    border-color: var(--dark-mode-border-color);
    color: var(--dark-mode-text-color);
    &:hover {
      background-color: var(--dark-gray-color);
    }
  }
  @media (max-width: 1300px) {
    width: auto;
    margin: 8px 0;
    padding: 0.625rem;
  }
}
.chat-input-container {
  width: 70vw;
  bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  @media (max-width: 1300px) {
    width: 88vw;
  }
  @media (max-width: 800px) {
    bottom: 6rem;
  }
}
.chat-input-sub-container {
  /* position: fixed; */
  flex: 0.5;
  background-color: var(--light-mode-bg-main-body-color);
  border-radius: 0.8485rem;
  border: 1px solid var(--light-gray-color);
  padding: 12px;
  .input {
    border: none;
    outline: none;
    font-size: 0.95rem;
    background-color: var(--light-mode-bg-main-body-color);
    padding: 1%;
    font-weight: 400;
    font-family: Inter;
    width: 98%;
  }
  .input::placeholder {
    color: var(--gray-color);
  }
  &-dark {
    @extend .chat-input-sub-container;
    background-color: var(--dark-mode-bg-main-body-color);
    border: 1px solid var(--dark-mode-border-color);
    .input {
      color: var(--dark-mode-text-color);
      background-color: var(--dark-mode-bg-main-body-color);
      width: 98%;
    }
  }
  .chat-input-send-icon {
    margin-right: 5px;
    padding: 6px 6px 6px 6px;
    border-radius: 6px;
    &-green {
      @extend .chat-input-send-icon;
      background-color: #6D4AFF;
      &-dark {
        @extend .chat-input-send-icon;
        background-color: #9E87FF;
      }
    }
    &-dark {
      @extend .chat-input-send-icon;
      background-color: var(--dark-mode-bg-main-body-color);
    }
  }
}

.chat-sidebar {
  z-index: 1;
  width: 16rem;
  height: 95%;
  border-radius: 0.95919rem 0.95919rem 1.5625rem 1.5625rem;
  position: fixed;
  color: var(--light-mode-text-color);
  background-color: var(--light-mode-bg-card-color);
  font-size: 0.90444rem;
  font-family: Plus Jakarta Sans, Inter;
  text-align: center;
  font-style: 'normal';
  line-height: 0.90444rem;
  left: 2rem;
  top: 1.2rem;
  box-shadow: rgba(112, 144, 176, 0.11) 0px 13.0447px 30.6934px 3.06934px;
  &-dark {
    @extend .chat-sidebar;
    color: var(--dark-mode-text-color);
    background-color: var(--dark-mode-bg-card-color);
    box-shadow: 14px 27px 60px 0px rgba(0, 0, 0, 0.03);
    // box-shadow: none;
  }
}
.active-sidebar {
  transform: translate(0);
}
.inactive-sidebar {
  transform: translateX(-20rem);
}
.hamburger-menu {
  position: absolute;
  left: 1%;
  top: 2%;
  &-dark {
    @extend .hamburger-menu;
  }
}
.hamburger-menu.isInactive {
  display: none;
}
.chat-sidebar-header {
  margin-top: 18%;
  margin-bottom: 6%;
}

.chat-sidebar-header-separator {
  border: 1px solid var(--light-gray-color);
  &-dark {
    @extend .chat-sidebar-header-separator;
    border: 1px solid var(--dark-mode-border-color);
  }
}

.chat-sidebar-back-btn {
  position: absolute;
  right: -0.7rem;
  top: 5.1rem;
  &-dark {
    @extend .chat-sidebar-back-btn;
  }
  @media (min-width: 1300px) {
    display: none;
  }
}

.chat-sidebar-list {
  overflow: auto;
  // height: 40rem;
  flex: 1;
  &-dark {
    @extend .chat-sidebar-list;
    &::-webkit-scrollbar-thumb {
      background-color: var(--dark-mode-text-color-light-gray);
    }
    &::-webkit-scrollbar {
      background-color: var(--black-color);
    }
  }
}

.chat-sidebar-item {
  margin-bottom: 3%;
  padding: 3%;
  font-size: 0.90444rem;
  font-weight: 500;
  color: var(--pale-blue-color);
  .chat-name {
    align-self: flex-start;
    padding: 0.08rem 0.5rem;
  }
  &:hover {
    background-color: var(--light-gray-color);
  }
  &-selected {
    @extend .chat-sidebar-item;
    color: var(--light-mode-text-color);
    
  }
  &-dark {
    @extend .chat-sidebar-item;
    color: var(--gray-color);
    &:hover {
      background-color: var(--dark-mode-border-color);
    }
    &-selected {
      @extend .chat-sidebar-item;
      color: var(--dark-mode-text-color);
      &:hover {
        background-color: var(--dark-gray-color);
      }
    }
  } 
}

.chat-sidebar-footer {
  // position: absolute;
  // bottom: 0;
  // flex: auto;
  height: 10rem;
  align-self: flex-end;
  border-radius: 1.5625rem;
  display: flex;
  align-items: center;
  &-dark {
    @extend .chat-sidebar-footer;
    background-color: var(--black-color);
  }
}

.new-chat-btn-ripple-wrapper {
  width: 100%;
  padding: 0px;
  border-radius: 6.948rem;
}
.new-chat-btn {
  font-size: 15px;
  padding: 18px 15px;
  font-weight: 400;
  font-family: Inter;
  border: none;
  background-color: var(--light-shade-black-color);
  &-dark {
    @extend .new-chat-btn;
    background-color: var(--dark-mode-bg-card-color);
  }
}

.user-info-btn {
  font-size: 15px;
  padding: 18px 15px;
  margin: 10px auto;
  font-weight: 400;
  font-family: Inter;
  color: var(--light-mode-text-color);
  background-color: var(--light-mode-bg-card-color);
  border: 1px solid var(--light-gray-color);
  &-dark {
    @extend .user-info-btn;
    border: 1px solid var(--dark-mode-border-color);
    color: var(--dark-mode-text-color);
    background-color: var(--black-color);
  }
}
.sign-out-btn {
  z-index: 2;
  position: absolute;
  right: 2rem;
  scale: 1.7;
  border-radius: 100px;
  &:hover {
    background-color: var(--light-gray-color);
  }
  &-dark {
    @extend .sign-out-btn;
    &:hover {
      background-color: var(--dark-mode-bg-card-color);
    }
  }
}

// .markdown-msg {
  // border-collapse: collapse;
  // width: 100%;
  // /* padding: 10px; */
  // border-radius: 20px;
  // clip: auto;
  // th, td {
  //   border: 1px solid #ccc;
  //   padding: 8px;
  //   text-align: left;
  // }
  // table {
  //   width: 100%;
  //   border-collapse: separate;
  //   border-spacing: 0;
  //   border: 1px solid #bababa;
  //   margin-bottom: 20px;
  //   border-radius: 10px;
  //   overflow: hidden;
  //   th, td {
  //     border: 1px solid #dcdcdc;
  //     padding: 8px;
  //   }
  //   th {
  //     background-color: #5f59ff;
  //     color: white;
  //     font-weight: 500;
  //     font-size: 14px;
  //     text-transform: uppercase;
  //     letter-spacing: 1.2px;
  //   }
  //   tr:nth-child(even) {
  //     background-color: #f9f9f9;
  //   }
  // }

  // &-dark {
  //   @extend .markdown-msg;
    // table {
    //   tr:nth-child(even) {
    //     background-color: var(--gray-color);
    //   }
    // }
  // }
// }
